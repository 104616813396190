import AppLayout from '@/layouts/app-layout.vue';
import localeMessage from '@/localeMessage';

export const usersRoutes = [
  {
    path: '/users',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'users',
        component: () => import('../views/users/users.vue'),
        meta: {
          title: localeMessage.get('profile_setting'),
          requiresAuth: true
        }
      },
      {
        path: 'permissions',
        name: 'permissions',
        component: () => import('../views/users/permissions.vue'),
        meta: {
          title: localeMessage.get('profile_setting'),
          requiresAuth: true
        }
      },
      {
        path: 'permissions/new',
        name: 'permissions-add',
        component: () => import('../views/users/permission.vue'),
        meta: {
          title: localeMessage.get('add_permission'),
          requiresAuth: true
        }
      },
      {
        path: 'permissions/:id',
        name: 'permissions-edit',
        component: () => import('../views/users/permission.vue'),
        meta: {
          title: localeMessage.get('edit_permission'),
          requiresAuth: true
        }
      },
      {
        path: 'profile-setting',
        name: 'profile-setting',
        component: () => import('../views/users/profile_setting.vue'),
        meta: {
          title: localeMessage.get('profile_setting'),
          requiresAuth: true
        }
      }
    ]
  },
];