import axios from 'axios';

class AuthService {
  constructor () {
    this.token = window.localStorage.getItem('token');
    let userData = window.localStorage.getItem('userData');
    this.user = userData ? JSON.parse(userData) : null;

    if (this.token) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
    }
  }
  login (token, user, permissions) {
    window.localStorage.setItem('token', token);
    window.localStorage.setItem('userData', JSON.stringify(user));
    window.localStorage.setItem('permissions', JSON.stringify(permissions));
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

    this.token = token;
    this.user = user;
  }
  check () {
    return !! this.token;
  }
  logout () {
    //window.localStorage.clear();
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('userData');
    this.token = null;
    this.user = null;
  }
  setUser (user) {
    window.localStorage.setItem('userData', JSON.stringify(user));
    this.user = user;
  }
}
export default new AuthService();
