import AuthLayout from '@/layouts/auth-layout.vue';
import localeMessage from '@/localeMessage';

export const errorRoutes = [
  //settings
  {
    path: '/error',
    component: AuthLayout,
    children: [
      // Error pages
      {
        path: '404',
        name: '404',
        component: () => import('../views/pages/error.vue'),
        props: {
          code: 404,
        },
        meta: {
          title: localeMessage.get('404'),
        }
      },
      {
        path: '419',
        name: '419',
        component: () => import('../views/pages/error.vue'),
        props: {
          code: 419,
        },
        meta: {
          title: localeMessage.get('419'),
        }
      },
      {
        path: '403',
        name: '403',
        component: () => import('../views/pages/error.vue'),
        props: {
          code: 403,
        },
        meta: {
          title: localeMessage.get('403'),
        }
      },
      {
        path: '500',
        name: '500',
        component: () => import('../views/pages/error.vue'),
        props: {
          code: 500,
        },
        meta: {
          title: localeMessage.get('500'),
        }
      }
    ]
  },
];