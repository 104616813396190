import AuthLayout from '@/layouts/auth-layout.vue';
import localeMessage from '@/localeMessage';

export const loginRoutes = [
  {
    path: '/login',
    component: AuthLayout,
    children: [
      {
        path: '',
        name: 'login',
        component: () => import('../views/auth/login.vue'),
        meta: {
          title: localeMessage.get('login'),
        },
      }
    ]
  },
];