import { createRouter, createWebHistory } from 'vue-router';

import AuthService from '../AuthService';
import { dashboardRoutes } from '@/router/dashboardRoutes';
import { loginRoutes } from '@/router/loginRoutes';
import { usersRoutes } from '@/router/usersRoutes';
import { settingsRoutes } from '@/router/settingsRoutes';
import { errorRoutes } from '@/router/errorRoutes';

const DEFAULT_TITLE = 'HD project';

const routes = [
  ...dashboardRoutes,
  ...loginRoutes,
  ...usersRoutes,
  ...settingsRoutes,
  ...errorRoutes,
];

const router = new createRouter({
    //mode: 'history',
    history: createWebHistory(),
    linkExactActiveClass: 'active',
    routes: routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { left: 0, top: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (AuthService.check()) {
            next();
        } else {
            router.push({ name: 'login' });
        }
    } else {
        next();
    }
});

router.afterEach((to, from) => {
    document.title = to.meta.title ?? DEFAULT_TITLE;
});



export default router;
