<template>
  <div class="row">
    <div id="tabsSimple" class="col-lg-12 col-12 layout-spacing mb-0 pb-0">
      <div class="statbox panel box box-shadow">
        <div class="panel-body simple-tab tabs">
          <ul class="nav nav-tabs mb-3 mt-3" id="simpletab" role="tablist">
            <li class="nav-item">
              <a class="nav-link active"
                 id="cassa-tab"
                 data-bs-toggle="tab"
                 href="#cassa"
                 role="tab"
                 aria-controls="cassa"
                 aria-selected="true">{{$t('cassa')}}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link"
                 id="balance-tab"
                 data-bs-toggle="tab"
                 href="#balance"
                 role="tab"
                 aria-controls="balance"
                 aria-selected="false">{{$t('balance')}}</a>
            </li>
          </ul>
          <div class="tab-content" id="simpletabContent">
            <div class="tab-pane fade show active" id="cassa" role="tabpanel" aria-labelledby="cassa-tab">
              <div class='row'>
                <div class='col-6'>
                  <div v-if='status === "open"'>
                    <button type="button"
                          class="btn btn-primary"
                          v-on:click="add"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg"
                         width="24"
                         height="24"
                         viewBox="0 0 24 24"
                         fill="none"
                         stroke="currentColor"
                         stroke-width="2"
                         stroke-linecap="round"
                         stroke-linejoin="round"
                         class="feather feather-shopping-cart"
                         data-v-5522efca="">
                      <circle cx="9" cy="21" r="1"></circle>
                      <circle cx="20" cy="21" r="1"></circle
                      ><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                    </svg>
                    <span class='ps-2'>{{$t('new_order')}}</span></button>
                  </div>
                  <div v-if='status === "closed"'>
                    <span class='text-info fw-bold fs-1'>{{$t('cashbox_closed')}}</span>
                  </div>

                </div>
                <div class='col-6'>
                  <div v-if="status === 'closed'" class='d-flex justify-content-end'>
                    <button type="button"
                            class="btn btn-primary"
                            v-on:click="openWithBalance"
                    >{{$t('open_cassa')}}</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="balance" role="tabpanel" aria-labelledby="balance-tab">
              <div v-if='status === "open"' class='d-flex justify-content-around'>
                <span class=''>{{$t('open_balance')}}: <strong>{{balance}} Ft.</strong></span>
                <span class=''>{{$t('cash')}}: <strong>{{dailyCash}} Ft.</strong></span>
                <span class=''>{{$t('card')}}: <strong>{{dailyCard}} Ft.</strong></span>
                <span class=''>{{$t('cashBalance')}}: <strong>{{cashBalance}} Ft.</strong></span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="row layout-top-spacing">
    <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
      <div class="panel p-0">
<!--        <div class="custom-table table3" v-if='status === "open"'>-->
        <div class="custom-table table3">
          <v-client-table :data="orders" :columns="columns" :options="table_option">
            <template #order.payment_method='props'>
              <span v-if="props.row.order.payment_method === 'cash'" class='badge badge-success'>{{$t('cash')}}</span>
              <span v-if="props.row.order.payment_method === 'card'" class='badge badge-info'>{{$t('card')}}</span>
            </template>
            <template #actions="props">
              <span v-if="props.row.isDeleted" class='badge badge-danger'>{{$t('deleted')}}</span>

              <a v-if="!props.row.isDeleted" href="javascript:;" class="cancel text-danger" @click="deleteOrder(props.row)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-x-circle table-cancel"
                >
                  <circle cx="12" cy="12" r="10"></circle>
                  <line x1="15" y1="9" x2="9" y2="15"></line>
                  <line x1="9" y1="9" x2="15" y2="15"></line>
                </svg>
              </a>
            </template>
          </v-client-table>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
  import '@/assets/sass/components/tabs-accordian/custom-tabs.scss';
  import '@/assets/sass/widgets/widgets.scss';
</script>

<script>
  import axios from 'axios';
  import localeMessage from '@/localeMessage';
  import { ref } from 'vue';
  export default {
    name: 'Cassa',
    data() {
      return {
        status: null,
        statusName: null,
        balance: null,
        dailyCash: 0,
        dailyCard: 0,
        cashBalance: 0,
        orders: [],
        columns: ['id', 'employee.name','product.name','product.description','quantity','product.uoms.name', 'full_price','date','order.payment_method', 'actions'],
        table_option: ref({
          perPage: 25,
          perPageValues: [25, 50, 100],
          skin: 'table table-hover',
          columnsClasses: { actions: 'actions text-center', "order.payment_method": 'text-center', "quantity": 'text-center', 'product.uoms.name': 'text-center', full_price: 'text-center' },
          headings: {
            id: '#',
            "employee.name": localeMessage.get('hairdresser'),
            "product.name": localeMessage.get('product_name'),
            "product.description": localeMessage.get('product_desc'),
            "product.uoms.name": localeMessage.get('unit'),
            "quantity": localeMessage.get('quantity'),
            full_price: localeMessage.get('price'),
            date: localeMessage.get('date'),
            "order.payment_method": localeMessage.get('payment_method'),
            actions: localeMessage.get('delete_order')
          },
          pagination: { nav: 'scroll', chunk: 5 },
          texts: {
            count: 'Showing {from} to {to} of {count}',
            filter: '',
            filterPlaceholder: 'Search...',
            limit: 'Results:',
          },
          sortable: ['id', 'employee.name','product.name','product.description','full_price', 'date'],
          sortIcon: {
            base: 'sort-icon-none',
            up: 'sort-icon-asc',
            down: 'sort-icon-desc',
          },
          orderBy: {
            column: 'date',
            ascending: false
          },
          resizableColumns: false,
          cellClasses:{
            id: [
              {
                class:'text-danger',
                condition: row => row.isDeleted === true
              }
            ],
            "employee.name": [
              {
                class:'text-danger',
                condition: row => row.isDeleted === true
              }
            ],
            "product.name": [
              {
                class:'text-danger',
                condition: row => row.isDeleted === true
              }
            ],
            "product.description": [
              {
                class:'text-danger',
                condition: row => row.isDeleted === true
              }
            ],
            full_price: [
              {
                class:'text-danger',
                condition: row => row.isDeleted === true
              }
            ],
            date: [
              {
                class:'text-danger',
                condition: row => row.isDeleted === true
              }
            ],

          }
        }),

      }
    },
    mounted() {
      this.checkStatus();
      this.getOrders();
    },
    methods: {
      checkStatus() {
        axios.get(process.env.VUE_APP_BACKEND_SERVER + '/api/cashFlow/status')
          .then((response) => {
            this.status = response.data.status;
            this.statusName = localeMessage.get(this.status);
            this.balance = response.data.opening_balance;
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getOrders() {
        axios.get(process.env.VUE_APP_BACKEND_SERVER + '/api/order/today')
          .then((response) => {
            this.orders = response.data;
          })
          .catch((error) => {
            console.log(error);
          }).finally(() => {
            this.calculateCash();
          });
      },
      add() {
        this.$router.push({ name: 'new-order' });
      },
      openWithBalance() {
        new window.Swal({
          title: localeMessage.get('no_opening_balance'),
          input: 'text',
          inputLabel: localeMessage.get('opening_balance_label'),
          inputValue: '',
          showCancelButton: true,
          inputValidator: (value) => {
            if (!value) {
              return 'Kötelező megadni!'
            }

            if (isNaN(value)) {
              return 'Csak számot lehet megadni!'
            }
          }
        }).then((result) => {
          if (result.isConfirmed) {
            axios.post(process.env.VUE_APP_BACKEND_SERVER + '/api/cashFlow/open', {
              opening_balance: result.value,
            }).then((response) => {
                this.checkStatus();
            }).catch((error) => {
                console.log(error);
            });
          }
        })
      },
      deleteOrder(order) {
        new window.Swal({
          title: localeMessage.get('delete_order'),
          icon: 'error',
          input: 'text',
          inputLabel: localeMessage.get('reason'),
          showCancelButton: true,
          inputValidator: (value) => {
            if (!value) {
              return 'You need to choose something!'
            }
          }}
          ).then((result) => {
          if (result.isConfirmed) {
            axios.delete(process.env.VUE_APP_BACKEND_SERVER + '/api/order/' + order.id, {
              data: {
                reason: result.value
              }
            }).then((response) => {
              this.getOrders();
            }).catch((error) => {
              console.log(error);
            });
          }
        })
      },
      calculateCash() {
        this.dailyCash = 0;
        this.orders.forEach((data) => {
          if (data.order.payment_method === 'cash' && data.order.isDeleted === false) {
            this.dailyCash += data.full_price;
          }
          if (data.order.payment_method === 'card' && data.order.isDeleted === false) {
            this.dailyCard += data.full_price;
          }

          this.cashBalance = this.balance + this.dailyCash;
        });
      },
    }
  };
</script>

<style scoped>

</style>