<template>
    <div class="layout-px-spacing dash_1">
        <teleport to="#breadcrumb" v-if="isMounted">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="javascript:;">{{ $t('dashboard') }}</a></li>
                                <li class="breadcrumb-item active" aria-current="page"><span>{{ $t('home') }}</span></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>

        <div class="row layout-top-spacing">
          <cassa></cassa>
        </div>
    </div>
</template>

<script setup>
    import { useMeta } from '@/composables/use-meta';

    import Cassa from '@/views/cashflow/Cassa.vue';

    //useMeta({ title: 'HOME' });

</script>
<script>
  export default {
    name: 'Index',
    data() {
      return {
        isMounted: false
      }
    },
    mounted() {
      this.isMounted = true
    }
  };
</script>
