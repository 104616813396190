<template>
  <router-view></router-view>
  <notifications class='mt-3 me-3'/>
</template>
<script setup>
    import '@/assets/sass/app.scss';
</script>
<script>
  export default {
    name: "App"
  };
</script>
