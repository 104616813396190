import i18n from '@/i18n';

class LocaleMessage {
  get(key) {
    let lang = localStorage.getItem('i18n_locale');
    if (i18n.global.messages[lang] !== undefined) {
      return i18n.global.messages[lang][key] ?? key;
    }
    return key;
  }

}

export default new LocaleMessage();