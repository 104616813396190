import AppLayout from '@/layouts/app-layout.vue';
import Home from '@/views/index.vue';
import localeMessage from '@/localeMessage';

export const dashboardRoutes = [
  {
    path: '/',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'home',
        component: Home,
        meta: {
          requiresAuth: true,
          title: localeMessage.get('dashboard'),
        }
      }
    ]
  },
  {
    path: '/exports',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'exports',
        component: () => import('@/views/exports/ExcelExports.vue'),
        meta: {
          requiresAuth: true,
          title: localeMessage.get('exports'),
        }
      }
    ]
  },
  {
    path: '/employeeStat',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'employeeStat',
        component: () => import('@/views/stat/EmployeeStatComponent.vue'),
        meta: {
          requiresAuth: true,
          title: localeMessage.get('dashboard'),
        }
      }
    ]
  },
  {
    path: '/new-order',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'new-order',
        component: () => import('@/views/order/NewOrder.vue'),
        meta: {
          requiresAuth: true,
          title: localeMessage.get('newOrder'),
        }
      }
    ]
  },
];